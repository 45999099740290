


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import IconMessageSend from '@/_modules/icons/components/icon-message-send.vue';
import { mapGetters } from 'vuex';
import { TChatMessageContext } from '@/_modules/chat/types/chat-message.type';
import { TContact } from '@/_types/contact.type';

const PROGRESS_ANIMATION_TOTAL_DURATION = 5000;
const TENCENT_MESSAGE_TEXT_LIMIT = 9000; // TODO: rename const since we are not using Tencent. Is 9000 okay? We can have our own limit.

@Component({
  components: {
    IconMessageSend,
  },
  computed: {
    ...mapGetters({
      myself: 'promoPageStore/contact',
    })
  }
})
export default class ChatMessageComposer extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  @Prop({ type: String })
  public readonly groupId: string;

  public readonly myself: TContact;

  public isSendingMessage: boolean = false;
  public newMessageText: string = '';

  public isSendingThrottled: boolean = false;
  public isSendingThrottleVisible: boolean = false;
  public sendingThrottleStart: Date;
  public sendingThrottleInterval: number;
  public sendingThrottleRemainingSeconds: string = '0.0';
  public sendingThrottleProgressStyle: string = 'width: 0';

  public get isMessageOverLengthLimit(): boolean {
    return this.newMessageText.length >= TENCENT_MESSAGE_TEXT_LIMIT;
  }

  public get myFullName(): string {
    if (!this.myself) {
      return '';
    }
    const name = this.myself.name ? this.myself.name.trim() : '';
    const surname = this.myself.surname ? this.myself.surname.trim() : '';
    const result: string = [name, surname].join(' ').trim();
    if (result) {
      return result;
    }

    return '';
  }

  public beforeDestroy(): void {
    if (this.sendingThrottleInterval) {
      window.clearInterval(this.sendingThrottleInterval);
    }
  }

  public throttleNextSending(): void {
    // TODO: uncomment
    // this.sendingThrottleStart = new Date();
    // this.isSendingThrottled = true;
    //
    // this.sendingThrottleInterval = window.setInterval(() => {
    //   this.updateSendingThrottleProgress();
    // }, 200);
    //
    // window.setTimeout(() => {
    //   this.unThrottleNextSending();
    // }, PROGRESS_ANIMATION_TOTAL_DURATION);
  }

  public unThrottleNextSending(): void {
    this.isSendingThrottled = false;
    this.isSendingThrottleVisible = false;
    if (this.sendingThrottleInterval) {
      window.clearInterval(this.sendingThrottleInterval);
    }
    this.focusOnTextArea();
  }

  public updateSendingThrottleProgress(): void {
    if (!this.sendingThrottleStart) {
      this.sendingThrottleRemainingSeconds = '0.0';
    }
    const remainingMilliseconds = PROGRESS_ANIMATION_TOTAL_DURATION - ((new Date()).getTime() - this.sendingThrottleStart.getTime());
    this.sendingThrottleProgressStyle = 'width: ' + Math.max(0, Math.min(100, (100.0 - Math.round(remainingMilliseconds * 100.0 / PROGRESS_ANIMATION_TOTAL_DURATION)))) + '%';
    this.sendingThrottleRemainingSeconds = '' + Math.round( remainingMilliseconds / 100.0 ) / 10.0;
    if (this.sendingThrottleRemainingSeconds.indexOf('.') === -1) {
      this.sendingThrottleRemainingSeconds += '.0';
    }
  }

  public onButtonMessageSendClick(): void {
    this.sendMessage();
  }

  public onTextareaKeypress(event: KeyboardEvent): void {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  public focusOnTextArea(): void {
    if (!this.$refs.textarea) {
      return;
    }
    this.$nextTick(() => {
      (this.$refs.textarea as HTMLTextAreaElement).focus();
    });
  }

  private async sendMessage(): Promise<void> {
    let messageText = '';
    if (this.$refs.textarea) {
      messageText = (this.$refs.textarea as HTMLTextAreaElement).value.trim();
    }

    if (!messageText) {
      return;
    }
    if (this.isSendingThrottled) {
      this.isSendingThrottleVisible = true;
      return;
    }
    if (this.isSendingMessage) {
      return;
    }

    this.isSendingMessage = true;
    this.throttleNextSending();

    const chatMessageContext: TChatMessageContext = {
      name: this.myFullName ? this.myFullName : '',
      photo_url: this.myself.photo_url ? this.myself.photo_url : '',
      contact_id: this.myself.id,
      platform: 'Web'
    };

    this.$store.dispatch('chatStore/sendChatGroupTextMessage', {
      groupId: this.groupId,
      message: messageText,
      context: chatMessageContext
    });
    this.clearTextArea();
    this.isSendingMessage = false;
    this.focusOnTextArea();
  }

  private clearTextArea(): void {
    this.newMessageText = '';
  }

}
