


import { Component, Prop, Vue } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import DateTimeHelper from '@/_helpers/date-time.helper';
import { ChatMessageType } from '@/_modules/chat/types/chat-message-type.enum';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import IconTrashDelete from '@/_modules/icons/components/icon-trash-delete.vue';

@Component({
  components: {
    EwAvatar,
    IconTrashDelete
  },
})
export default class ChatMessage extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') contact: TContact;
  @Getter('contactsStore/contactById') contactById: (contactId: number) => TContact;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number }) => void;

  @Prop({ type: Object, default: null })
  public readonly message: TChatMessage;

  @Prop({type: Boolean, default: false})
  public readonly showDayRow: boolean;

  public readonly ChatMessageType: typeof ChatMessageType = ChatMessageType;

  public isShowRemoveConfirmation: boolean = false;

  // public created(): void {
  //   this.onMessageChange();
  // }

  public get eventId(): number {
    return (this.event && this.event.id) || null;
  }

  public get isMine(): boolean {
    return this.contact && this.messageContactId === this.contact.id;
  }

  public get time(): string {
    return DateTimeHelper.messageTime(this.message.time);
  }

  public get day(): string {
    return DateTimeHelper.messageDay(this.message.time, this.$i18n.locale);
  }

  public get messageContactId(): number {
    const contactIdFromContext: number = (this.message && this.message.data && this.message.data.contact_id ? this.message.data.contact_id : null);
    const contactIdFromMessage: number = (this.message && this.message.from ? this.message.from : null);
    return contactIdFromContext || contactIdFromMessage || null;
  }

  public get messageContact(): TContact {
    const messageContactId = this.messageContactId;
    return messageContactId ? this.contactById(messageContactId) : null;
  }

  public get messageAuthorFullName(): string {
    if (this.message && this.message.data && this.message.data.name) {
      return this.message.data.name;
    } else if (this.messageContact && this.messageContact.fullName) {
      return this.messageContact.fullName;
    }
    return (this.$t('contacts.info.noname') as string);
  }

  public get messageAuthorPhotoUrl(): string {
    if (this.message && this.message.data && this.message.data.photo_url) {
      return this.message.data.photo_url;
    } else if (this.messageContact && this.messageContact.photo_url) {
      return this.messageContact.photo_url;
    }
    return '';
  }

  public get generateLinksWithImagePreviews(): string {
    return ChatHelper.createLinksWithImagePreviews(this.message.message);
  }

  public removeMessageConfirm(isConfirmed: boolean, messageId: number): void {
    if (isConfirmed) {
      this.isShowRemoveConfirmation = false;
      this.$emit('removeMessage', messageId);
    } else {
      this.isShowRemoveConfirmation = false;
    }
  }

  private async showRemoveMessageConfirmation(): Promise<void> {
    this.isShowRemoveConfirmation = true;
  }

  public onMessageAuthorClick(): void {
    if (this.isMine) {
      return;
    }
    this.openContactCard({contactId: this.messageContactId});
  }
}
